import * as types from "./constants.jsx";

export function getPurchases(article) {
    return {
        type: types.GET_PURCHASES,
        payload: {
            client: "default",
            request: {
                url: `/accounts/company-orders/search/?detailnum=${article}`,
                method: "get",
            },
        },
    };
}
export function getPurchasesDetail(article, producer, description) {
    return {
        type: types.GET_PURCHASES_DETAIL,
        payload: {
            client: "default",
            request: {
                url: `/accounts/company-orders/detail-search/?article_number=${article}&producer=${producer}&description=${description}`,
                method: "get",
            },
        },
    };
}

export function getMyPurchases(data) {
    return {
        type: types.GET_MY_PURCHASES,
        payload: {
            client: "default",
            request: {
                url: `/accounts/company-orders/orders/${
                    data.length && data.length !== 0 ? "?" : ""
                }${data.join("&")}`,
                method: "get",
            },
        },
    };
}

export function getMyRequests(arr) {
    return {
        type: types.GET_MY_REQUESTS,
        payload: {
            client: "default",
            request: {
                url: `/accounts/company-orders/refund/${
                    arr.length && arr.length !== 0 ? "?" : ""
                }${arr.join("&")}`,
                method: "get",
            },
        },
    };
}

export function getRefundRequest(id) {
    return {
        type: types.GET_REFUND_REQUEST,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/company-orders/refund/${id}`,
                method: "get"
            }
        }
    };
}

export function postDialogMessage(data) {
    return {
        type: types.POST_DIALOG_MESSAGE,
        payload: {
            client: "default",
            request: {
                url: `/refund/message/`,
                method: "post",
                data,
            },
        },
    };
}

export function getMyTransactions(arr) {
    return {
        type: types.GET_MY_TRANSACTIONS,
        payload: {
            client: "default",
            request: {
                url: `/accounts/users/transactions-history/${
                    arr.length && arr.length !== 0 ? "?" : ""
                }${arr.join("&")}`,
                method: "get",
            },
        },
    };
}

export function postCreateOrders(data) {
    return {
        type: types.JAPARTS_ORDERS_CREATE,
        payload: {
            client: "default",
            request: {
                url: `/accounts/company-orders/orders/`,
                method: "post",
                data,
            },
        },
    };
}

export function getMyJapartsOrders(ordernum) {
    return {
        type: types.JAPARTS_GET_ORDER,
        payload: {
            client: "default",
            request: {
                url: `/accounts/company-orders/orders/?ordernum=${ordernum}`,
                method: "get",
            },
        },
    };
}

export function postCreateRequest(data) {
    return {
        type: types.JAPARTS_REQUEST_CREATE,
        payload: {
            client: "default",
            request: {
                url: `/accounts/company-orders/refund/`,
                method: "post",
                data,
            },
        },
    };
}

export const clearDataItem = () => {
    return {
        type: types.CLEAR_DATA_ITEM,
        items: [],
        items_detail: {},
    };
};
