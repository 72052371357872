import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, NavLink } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Badged from '../HelperComponents/Badged/Badged';
import PopperInfo from '../HelperComponents/PopperInfo/PopperInfo';
import DefaultButton from '../Buttons/DefaultButton/DefaultButton';

import moment from 'moment';
import 'moment/locale/ru';

import { getNotifications, balanceInfo } from "../../actions/userActions";

import NotificationIcon from '@material-ui/icons/NotificationsOutlined';
import MessageIcon from '../../assets/image/comment.png';
import StarIcon from '../../assets/image/star.png';
import NewOrderIcon from '../../assets/image/icon-new-order.png';
import LogoHeader from '../../assets/image/logo_header.png';
import BasketIcon from '../../assets/image/basket.png';

import './Head.scss';
import InfoHead from '../InfoHead/InfoHead';

moment.locale("ru");

export function splitByCommas(data) {
    return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class Head extends Component {


    state = {
        anchorEl: null,
        open: false,
        invisible: true,
        userBalance: {}
    };

    componentDidMount() {
        const { getNotifications, balanceInfo} = this.props;
        getNotifications().then(res => {
            if(res.payload && res.payload.status && res.payload.status === 200){
                res.payload.data.length !== 0 && this.setState({ invisible: false });
            }
        });
        balanceInfo().then(res => {
            if(res.payload && res.payload.status && res.payload.status === 200){
                this.setState({userBalance: res.payload.data});
            }
        })
    }

    componentWillReceiveProps(nextProps) {
        const { user: {notifications}, match } = this.props;
        if (window.location.href.indexOf('orders') === -1) {
            localStorage.removeItem('pageOrders');
            localStorage.removeItem('resultsOrders');
        }
        if(notifications.length > 0 && nextProps.user.notifications.length !== notifications.length) {
            nextProps.user.notifications.length === 0 &&  this.setState({ invisible: true });
        }
    }

    handleClick = event => {
        const { currentTarget } = event;
        this.setState(state => ({
            anchorEl: currentTarget,
            open: !state.open,
        }));
    };

    clickAway = () => {
        this.setState(({open}) => ({
            open: !open
        }));
    };

    goToRewiewsOredr = (e, id) => {
        const { history } = this.props;
        this.setState(({open}) => ({
            open: !open,
            anchorEl: null
        }));
        history.push(e === "review" ? '/admin/reviews' : `/admin/orders/${id}`);

    };

    logOut = () => {
        const { history } = this.props;
        localStorage.clear();
        history.push('/auth');
    };

    formatDate = (date) => {
        if (!date) {
            return '-'
        }

        const formattedDate = new Date(date);
        return new Intl.DateTimeFormat('ru-RU', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        }).format(formattedDate);
    }

    paymentClassName = () => {
        const { userBalance } = this.state;
        const currentSecondsDate = new Date(new Date().setMilliseconds(0)).getTime();
        const paymentDate = new Date(new Date(userBalance.date).setMilliseconds(0)).getTime();
        if (currentSecondsDate > paymentDate) {
            return 'late-payment';
        }

        return 'payment';
    }

    render(){
        const { user: {notifications}, basket_item_count, role } = this.props;

        const { anchorEl, open, invisible, userBalance} = this.state;
        return (
            <Fragment>
                <header className="app-head container ">
                    <div className="flex-center-btw header-wrapper">
                        <div className="flex-center">
                            <Link to="/admin">
                                <img src={LogoHeader} alt="logo header"/>
                            </Link>
                            <nav className="app-nav">
                                { role !== undefined && role !== 'wholesale_customer' &&
                                    (
                                        <Fragment>
                                            <NavLink
                                                to="/admin/orders"
                                                className="nav_link"
                                                activeClassName="active_nav_link"
                                            >
                                                Заказы
                                            </NavLink>
                                            <NavLink
                                                to="/admin/price-list"
                                                className="nav_link"
                                                activeClassName="active_nav_link"
                                            >
                                                Прайсы
                                            </NavLink>
                                            <NavLink
                                                to="/admin/reviews"
                                                className="nav_link"
                                                activeClassName="active_nav_link"
                                            >
                                                Отзывы
                                            </NavLink>
                                            <NavLink
                                                to="/admin/purchases"
                                                className="nav_link"
                                                activeClassName="active_nav_link"
                                            >
                                                Закупки
                                            </NavLink>
                                        </Fragment>
                                    )
                                }
                            </nav>
                        </div>
                        <div className="app-head_options">
                            <div className="balance">
                                <div>Баланс: {userBalance.balance !== undefined && userBalance.balance !== 0 ? splitByCommas(userBalance.balance.toFixed(2)) : 0} ₽</div>
                                <div className="balance__payment-block">
                                    <NavLink
                                        to="/admin/purchases?tab=3"
                                    >
                                        <span>К оплате:&nbsp;
                                            <span className={this.paymentClassName()}>
                                                {userBalance.payment !== undefined && userBalance.payment !== 0 ? splitByCommas(userBalance.payment.toFixed(2)) : 0} ₽ | {this.formatDate(userBalance.date)}
                                            </span>
                                        </span>
                                    </NavLink>
                                </div>
                            </div>

                            <Badged invisible={invisible}>
                                <IconButton
                                    classes={{
                                        root: 'notification_btn'
                                    }}
                                    onClick={ this.handleClick }
                                >
                                    <NotificationIcon/>
                                </IconButton>
                            </Badged>
                            <div className="basket-btn">
                                <Link
                                    to="/admin/basket"
                                >
                                    <img src={BasketIcon} alt="Basket"/>
                                    {basket_item_count ? <span className="item-basket">{basket_item_count}</span>: null}
                                </Link>
                            </div>


                            <Button
                                classes={{
                                    root: 'exit_btn'
                                }}
                                onClick={this.logOut}
                            >
                                Выйти
                            </Button>
                        </div>
                    </div>

                    <PopperInfo
                        open={open}
                        anchorEl={anchorEl}
                        clickAway={this.clickAway}
                    >
                        <ul className="notification_list">
                            {notifications && notifications.length>0 ?
                                notifications.map((e, i)=>{
                                        return <Fragment key={"notif"+ e.id}>
                                            <li className="notification_item" onClick={() => this.goToRewiewsOredr(e.unread, e.order)}>
                                                <div className="flex-center">
                                                    {e.unread === "review" ?
                                                        <Fragment>
                                                            <img src={StarIcon} alt="StarIcon"/>
                                                            <p className="notification_title"><span>{e.user}</span> оставил отзыв</p>
                                                        </Fragment>
                                                        : e.unread === "create" ?
                                                            <Fragment>
                                                                <img src={NewOrderIcon} alt="message icon"/>
                                                                <p className="notification_title"><span>{e.user}</span> создал заказ <span>{e.order !== null ? e.order : "-"}</span></p>
                                                            </Fragment>
                                                            :
                                                            <Fragment>
                                                                <img src={MessageIcon} alt="message icon"/>
                                                                <p className="notification_title"><span>{e.user}</span> оставил комментарий по заказу <span>{e.order !== null ? e.order : "-"}</span></p>
                                                            </Fragment>

                                                    }
                                                </div>
                                                <span className="notification_date">{moment(e.date).startOf('hour').fromNow()}</span>
                                            </li>
                                            {notifications.length - 1 === i ? "" : <hr/>}
                                        </Fragment>

                                    }
                                )
                                :
                                <li className="notification_item"> Нет уведомлений</li>
                            }
                        </ul>
                    </PopperInfo>
                </header>
                { role !== undefined && role === 'wholesale_customer' && <InfoHead />}            
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return{
        user: state.user,
        role: state.user.user_info.role,
        basket_item_count: state.cart.addedItems.length
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getNotifications,
        balanceInfo

    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Head);