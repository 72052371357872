import React, {Component} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import AddRepresentation from './AddRepresentation';
import IconButton from '@material-ui/core/IconButton';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import DeleteRepresentation from './DeleteRepresentation';
import Loader from '../HelperComponents/Loader/Loader';

import { days, isArray } from "../../helpers/functions";
import { getAddresses } from "../../actions/userActions";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

class InfoRepresentation extends Component {
    state = {
        open: [],
        mapOptions: [],
        loading: false,
    };

    componentDidMount() {
        const { getAddresses } = this.props;
        getAddresses().then(res => {
            if(res.payload && res.payload.status && res.payload.status === 200) {
                res.payload.data.forEach((el) => {
                    window.ymaps.ready(() => {
                        window.ymaps.geocode(el.coordinates).then(res => {
                            let obj = {
                                id: el.id,
                                center: res.geoObjects.get(0).geometry.getCoordinates(),
                                zoom: 12
                            };
                            this.setState(({mapOptions}) => ({mapOptions: [...mapOptions, obj], loading: true }));
                        });
                    });
                })
            }
        });
    }

    componentWillUnmount() {
        this.props.user.info_addresses_loaded = false;
        this.props.user.info_addresses = [];
    }

    componentWillReceiveProps(nextProps) {
        const { info_addresses } = this.props.user;
        if(info_addresses.length > 0 && nextProps.user.info_addresses.length !== info_addresses.length) {

            let el = nextProps.user.info_addresses[nextProps.user.info_addresses.length - 1];

            window.ymaps.ready(() => {
                window.ymaps.geocode(el.coordinates).then(res => {
                    let obj = {
                        id: el.id,
                        center: res.geoObjects.get(0).geometry.getCoordinates(),
                        zoom: 12
                    };
                    this.setState(({mapOptions}) => ({mapOptions: [...mapOptions, obj] }));
                });
            });

        }
    }

    setMapOption = (option) => {
        this.setState(({mapOptions}) => ({
            mapOptions: mapOptions.map(el => el.id === option.id ? option : el)
        }));
    };

    toggleOpen = id => {
        const { open } = this.state;
        if(open.includes(id)) {
            this.setState(({open}) => ({
                open: open.filter(el => el !== id)
            }));
        } else {
            this.setState(({open}) => ({
                open: [...open, id]
            }));
        }
    };

    render(){
        const { user:{ info_addresses, info_addresses_loaded } } = this.props;
        const { open, mapOptions, loading } = this.state;
        return (
            <div>
                <div className="title-info">
                    <h3>Представительства</h3>
                    <AddRepresentation
                        form="AddRepresentation"
                    />
                </div>
                {info_addresses_loaded ?
                    isArray(info_addresses) ?
                        info_addresses.map(({id, coordinates, workingtime}) => {
                            let idx = mapOptions.findIndex(el => el.id === id);
                            return (
                                <div className="expansion-block" key={id}>
                                    <span className="block_label">Адрес</span>
                                    <div className="expansion-title flex-center">
                                        <p onClick={() => this.toggleOpen(id)}>
                                            {coordinates}
                                            <IconButton>
                                                {open.includes(id)
                                                    ? <ExpandLessIcon/>
                                                    : <ExpandMoreIcon/>
                                                }
                                            </IconButton>
                                        </p>
                                        <AddRepresentation
                                            edit
                                            id={id}
                                            form={`Edit${id}Representation`}
                                            coordinates={coordinates}
                                            workingtime={workingtime}
                                            map={mapOptions[idx]}
                                            setMap={this.setMapOption}
                                        />
                                        {info_addresses.length !== 1 ?
                                            <DeleteRepresentation
                                                id={id}
                                            />
                                            : null
                                        }
                                    </div>
                                    <div className="expansion-body flex-center" style={{display: open.includes(id) ? 'flex' : 'none'}}>
                                        <div className="schedule">
                                            <span className="block_label">График работы</span>
                                            {workingtime.map(({working_day, from_hour, to_hour, is_enabled}, i) => (
                                                <div className={`schedule-time ${!is_enabled ? "schedule-time_inactive" : null}`} key={i}>
                                                    <span className="time_day">
                                                        {days.filter(el => el.name === working_day)[0].label}
                                                    </span>
                                                    <span className="time_value">
                                                        {is_enabled
                                                            ? `${from_hour} - ${to_hour}`
                                                            : 'Выходной'
                                                        }
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                        {loading && 
                                            <div className="schedule_map_wrapper">
                                                <YMaps>
                                                    <Map
                                                        width={537}
                                                        height={240}
                                                        state={mapOptions[idx]}
                                                    >
                                                        <Placemark geometry={mapOptions[idx] && mapOptions[idx].center} />
                                                    </Map>
                                                </YMaps>
                                            </div>
                                        }
                                    </div>
                                </div>
                            );
                        })
                        :
                        <p className="info_section_no_items block_value">У вас нет добавленых представительств</p>
                    :
                    <div className="info_section_loader">
                        <Loader/>
                    </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return{
        user: state.user
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getAddresses
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InfoRepresentation);